<template>
  <DefaultBody :item="item" @readNotif="overtimeReadNotif"></DefaultBody>
</template>
<script>
import { mapCategoryNotif } from '../../constant'
import DefaultBody from './DefaultBody.vue'

export default {
  props: ['item'],
  components: {
    DefaultBody,
  },
  data() {
    return {
      mapCategoryNotif: mapCategoryNotif,
    }
  },

  methods: {
    overtimeReadNotif(id) {
      this.$emit('readNotif', id)
      // TODO: Redirect ke halaman request detail
      if (this.item.userId == this.item.data.userId) {
        this.$router.push('/request/overtime?id=' + this.item.moduleId)
      } else {
        this.$router.push(
          '/request/overtime/management?id=' + this.item.moduleId
        )
      }
    },
  },
}
</script>
