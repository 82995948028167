<template>
  <r-notification-item
    @click="readNotif(item.id)"
    :item="item"
    :is-unread="item.isRead === 0"
  >
    <template #message>
      <span class="disabled-r-notification">;nbsp</span>
    </template>

    <template #detail>
      <div class="notification-hr-action">
        <div class="info">
          <span
            class="category"
            v-if="mapCategoryNotif.get(item.category)"
            :style="
              'background:' +
              mapCategoryNotif.get(item.category).background +
              '!important'
            "
          >
            {{ mapCategoryNotif.get(item.category).name }}
          </span>
          <span class="date" v-if="item.requestDate">
            {{ item.requestDate }}
          </span>
        </div>
        <div class="message">
          Contract some employees is about to expired, if you wish to renew
          please take note
          <br />
          <br />
          <span
            v-for="(user, index) in item.data"
            :key="index"
            class="list-item"
          >
            <li>
              {{ user.fullName }} on
              <b class="is-command">
                {{ formattime(user.endContractAt, 'DD MMMM YYYY') }}
              </b>
            </li>
          </span>
        </div>
      </div>
    </template>
  </r-notification-item>
</template>

<script>
import { mapCategoryNotif } from '../../constant'
import moment from 'moment'

export default {
  props: ['item'],
  data() {
    return {
      page: 0,
      lastPage: 0,
      mapCategoryNotif: mapCategoryNotif,
    }
  },
  methods: {
    readNotif(id) {
      this.$emit('readNotif', id)
    },
    formattime(date, format) {
      return moment.utc(date).local().format(format)
    },
  },
}
</script>

<style lang="css" scoped>
.disabled-r-notification {
  display: none !important;
}
.image-container {
  background-color: #fff !important;
}
</style>
