<template>
  <DefaultBody
    :item="item"
    @readNotif="attendanceAdjustmentReadNotif"
  ></DefaultBody>
</template>
<script>
import { mapCategoryNotif } from '../../constant'
import DefaultBody from './DefaultBody.vue'

export default {
  props: ['item'],
  components: {
    DefaultBody,
  },
  data() {
    return {
      mapCategoryNotif: mapCategoryNotif,
    }
  },

  methods: {
    attendanceAdjustmentReadNotif(id) {
      this.$emit('readNotif', id)
      // TODO: Redirect ke halaman request detail
      if (this.item.userId == this.item.data.userId) {
        this.$router.push(
          '/request/attendance-adjustment?id=' + this.item.moduleId
        )
      } else {
        this.$router.push(
          '/request/attendance-adjustment/management?id=' + this.item.moduleId
        )
      }
    },
  },
}
</script>
