<template>
  <r-notification-item
    @click="readNotif(item.id)"
    :item="item"
    :is-unread="item.isRead === 0"
  >
    <template #message>
      <strong>{{ item.fullName }}</strong>
      {{ item.message }}
    </template>

    <template #detail>
      <div class="notification-hr-action">
        <div class="info">
          <span class="category">
            <div v-if="mapCategoryNotif.get(item.category)">
              <b-icon :icon="mapCategoryNotif.get(item.category).icon" />
              {{ mapCategoryNotif.get(item.category).name }}
            </div>
            <div v-else>
              {{ item.category }}
            </div>
          </span>
          <span class="date" v-if="item.requestDate">
            {{ item.requestDate }}
          </span>
        </div>
        <div class="message" v-if="item.requestReason">
          Reason:
          <br />
          {{ item.requestReason }}
        </div>
      </div>
    </template>
  </r-notification-item>
</template>

<script>
import { mapCategoryNotif } from '../../constant'

export default {
  props: ['item'],
  data() {
    return {
      page: 0,
      lastPage: 0,
      mapCategoryNotif: mapCategoryNotif,
    }
  },
  methods: {
    readNotif(id) {
      this.$emit('readNotif', id)
    },
  },
}
</script>
